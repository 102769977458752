.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "RobotoBold";
  src: url('/fonts/Roboto-Bold.ttf')  format('truetype');
  }

@font-face {
  font-family: "RobotoMedium";
  src: url('/fonts/Roboto-Medium.ttf')  format('truetype');
  }


body {
  width: 100%;
  font-family: "RobotoBold";
  height: 100%;
  --tw-text-opacity: 1;
  color: rgba(17,24,39,var(--tw-text-opacity));
  background-color: #000000;
  background-image: url("./backImg.jpg");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
}




.rdtext{
  text-align: center;
  font-family: "RobotoBold";
  font-size: 32px;
  word-spacing: 1015px;
}   

.rdtext-roadmap {
  font-family: "RobotoBold";
  font-size: 24px;
  --tw-text-opacity: 1;
  color: rgba(236,72,153,var(--tw-text-opacity));
}

.faqone {
  background-color: #ffb6b6;
  text-transform: uppercase;
  color: #20201e;
}

.faqtwo {
  background-color: #ffe1b6;
}

.faqthree {
  background-color: #fffbb6;
}

.faqthree, .faqtwo {
  text-transform: uppercase;
  color: #20201e;
}

.faqfour {
  background-color: #b6ffb9;
}

.faqfive, .faqfour {
  text-transform: uppercase;
  color: #20201e;
}

.faqfive {
  background-color: #b6e6ff;
}

.accordion-title {
  display: flex;
  font-size: 20px;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.accordion {
  max-width: 900px;
  margin: 2rem auto;
}

.accordion, .text {
  font-family: "RobotoMedium";
}


.accordion-content, .accordion-title {
  padding: 1rem;
}

